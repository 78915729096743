<template>
  <div class="content">
    <siteHeader />
    <div class="container-fluid mt--9">
      <div>
        <card class="no-border-card" body-classes="pb-1" footer-classes="pb-2">
          <template v-slot:header>
            <h3>Account Details</h3>
          </template>
          <div v-if="isLoading">
            <base-alert type="danger" dismissible>
              <span class="alert-inner--text">
                Record Deleted Successfully</span
              >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>
          </div>
          <div>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <div>
                <base-button
                  type="primary"
                  class="mb-4"
                  size="sm"
                  @click="sendEmail()"
                >
                  <i class="fas fa-envelope-square" size="5x"></i>
                  Send Email</base-button
                >

                <base-button
                  type="primary"
                  class="mb-4"
                  size="sm"
                  @click="printtable()"
                >
                  <i class="fa fa-print mr-2" size="5x"></i>
                  Export</base-button
                >
              </div>

              <div>
                <el-input
                  type="search"
                  class="mb-3"
                  clearable
                  prefix-icon="el-icon-search"
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </div>
            </div>
            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @row-dblclick="dbSelected"
            >
              <!-- <el-table-column
                min-width="100px"
                align="right"
                label="Membership"
                sortable
              >
                <template v-slot:default="props">
                  <div class="d-flex">
                    <div v-for="item in membershipData" :key="item.id">
                      <span v-if="item.id === props.row.membership">
                        {{ (props.row.membership_data = item.membership_name) }}
                      </span>
                    </div>
                  </div>
                </template>
              </el-table-column> -->

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
                sortable
              >
              </el-table-column>

              <el-table-column
                min-width="110px"
                align="right"
                label="MemberGroup"
                sortable
              >
                <template v-slot:default="props">
                  <div class="d-flex">
                    <div v-for="item in memberGroupData" :key="item.id">
                      <span v-if="item.id === props.row.member_group">
                        {{
                          (props.row.member_group_data =
                            item.members_group_name)
                        }}
                      </span>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                min-width="120px"
                align="right"
                label="Last Sent Email"
                sortable
              >
                <template v-slot:default="props">
                  <div v-if="props.row.mail_file" class="d-flex">
                    <i
                      @click="open_url(props.row.mail_file)"
                      class="fa fa-print mr-2"
                      size="5x"
                    >
                    </i>
                    {{ props.row.email_sent ? props.row.email_sent : "N/A" }}
                  </div>
                  <div v-else class="d-flex">N/A</div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="100px"
                align="right"
                label="Actions"
                sortable
              >
                <template v-slot:default="props">
                  <div class="d-flex">
                    <i class="fas fa-edit" @click="onEdit(props.row)"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <div class="">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import siteHeader from "../../global/header.vue";
const membergroupRepository = RepositoryFactory.get("membergroup");
const memberShipRepository = RepositoryFactory.get("membership");
const memberRepository = RepositoryFactory.get("member");
const addchargeRepository = RepositoryFactory.get("addcharge");
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
export default {
  components: {
    siteHeader,
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  name: "projects-table",
  computed: {
    pagedData() {
      return this.memberData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.memberData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (
            rowValue.includes &&
            rowValue.toLowerCase().includes(this.searchQuery)
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.memberData.length;
    },
  },
  data() {
    return {
      searchQuery: "",
      isLoading: false,
      tableData: [],
      memberData: [],
      membershipData: [],
      subscriptionData: [],
      model: {},
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      propsToSearch: ["member_group_data", "name", "balance"],
      tableColumns: [
        {
          prop: "name",
          label: "First Name",
          minWidth: 100,
        },
        {
          prop: "lastname",
          label: "Last Name",
          minWidth: 100,
        },
        {
          prop: "balance",
          label: "Account Balance($)",
          minWidth: 120,
        },
      ],

      fuseSearch: null,
      searchedData: [],
    };
  },
  methods: {
    async sendEmail() {

      await addchargeRepository.sendEmails();
    },
    open_url(url) {
      window.open(url, "_blank");
    },
    printtable() {
         window.open(
        "https://shulsoftware.adass.com.au/api/index.php/Member/export_account",
        "_blank"
      );
    },
    async getList() {
      this.tableData = await memberRepository.getMember();
      this.memberData = this.tableData.data;
      for (let index = 0; index < this.memberData.length; index++) {
        const element = this.memberData[index];
        element.balance = element.balance ? element.balance : 0;
        this.memberData[index].balance = "$" + element.balance;
        element.prepaid = element.prepaid ? element.prepaid : 0;
        this.memberData[index].prepaid = "$" + element.prepaid;
      }
    },
    dbSelected(row) {
      row.edit = 1;
      localStorage.setItem("accountmember", JSON.stringify(row));
      this.$router.push("account_details");
    },
    onEdit(row) {
      row.edit = 1;
      localStorage.setItem("accountmember", JSON.stringify(row));
      this.$router.push("account_details");
    },
    async getMembershipList() {
      this.tableData = await memberShipRepository.getMembership();
      this.membershipData = this.tableData.data;
    },
    async getMemberGroupList() {
      this.tableData = await membergroupRepository.getMember();
      this.memberGroupData = this.tableData.data;
    },
  },
  async mounted() {
    await this.getMemberGroupList();
    // await this.getMembershipList();
    localStorage.removeItem("chargeaccout");
    await this.getList();
  },
};
</script>
<style></style>
